import React from "react";

import ScreeningInfoGraphic from "components/molecules/InfoGraphic/ScreeningInfoGraphic";
import RiskSummaryInfoGraphic from "components/molecules/RiskSummaryInfoGraphic";
import { RiskSummaryTopic } from "api/insights";
import { personReportSectionTitles } from "util/reportSectionTitles";
import { SCREENING_FILTERS, SCREENING_LIST_TYPES } from "util/screeningUtils";
import { REPORT_TYPES } from "util/reportTypes";
import { getConfirmedExplainerText, getForReviewExplainerText } from "./utils";

import S from "./styles";

/**
 * The logic in here works as follows:
 *
 * 1. If there are any DIRECT CONFIRMED hits in any of the screening icons, we do not show any RCA CONFIRMED hits.
 *
 * 2. If there are no DIRECT CONFIRMED hits in any of the screening icons, but there are RCA CONFIRMED hits, then
 * we display RCA data.
 *
 * In the case where there are no hits for either DIRECT or RCAs, then we default to a 0 count with the default (non-RCA) icon.
 *
 * Unconfirmed/for review counts will always be a sum of DIRECT and RCA, UNCONFIRMED hits.
 */
const ThemedScreeningInfographics = ({
  screeningData,
  reportSectionRefs,
  reportType,
  CustomInfographic
}) => {
  // Add backwards compatibility to handle older PDX reports expecting an older JSON structure
  const isOldScreeningStructure = Object.keys(screeningData).some(
    screeningType => {
      // If "confirmed" is the first property under each screening object, then
      // the data is in its old format.
      const data = screeningData[screeningType];
      return (
        data &&
        Object.prototype.hasOwnProperty.call(
          screeningData[screeningType],
          "confirmed"
        )
      );
    }
  );

  // If it is the old screening structure, convert it into the new structure
  let screeningDataToUse = screeningData;
  if (isOldScreeningStructure) {
    screeningDataToUse = Object.keys(screeningData).reduce(
      (acc, screeningType) => {
        acc[screeningType] = {
          direct: screeningData[screeningType],
          relativeOrCloseAssociate: {
            confirmed: [],
            unconfirmed: [],
            discarded: []
          }
        };
        return acc;
      },
      {}
    );
  }

  const hasDirectScreeningHits = Object.keys(screeningDataToUse).some(
    screeningType => {
      return screeningDataToUse[screeningType]?.direct?.confirmed?.length > 0;
    }
  );

  const { sanctions, watchlists, peps, dowJonesSpecialInterest } =
    screeningDataToUse;

  const dowJonesSpecialInterestHits = hasDirectScreeningHits
    ? dowJonesSpecialInterest?.direct?.confirmed
    : dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed;

  const dowJonesSpecialInterestFormatted = dowJonesSpecialInterestHits
    ?.flatMap(entry => {
      return entry.riskCategories?.map(category => category.category);
    })
    ?.filter(
      (riskCategory, index, riskCategoryArr) =>
        riskCategoryArr.indexOf(riskCategory) === index
    );

  const specialInterestRiskCategories = dowJonesSpecialInterestHits?.flatMap(
    flag => flag.riskCategories
  );

  const hasAnalystNotes = () => {
    if (
      dowJonesSpecialInterestFormatted &&
      dowJonesSpecialInterestFormatted?.source !== null
    ) {
      return dowJonesSpecialInterestHits.some(
        entry => entry?.source?.description !== null
      );
    }
    return false;
  };

  const containsMultipleSpecialInterestHits =
    dowJonesSpecialInterestHits?.length > 1;

  const specialInterestContent = (
    <>
      {!containsMultipleSpecialInterestHits ? (
        <S.SpecialInterestPopoverContent>
          <div>
            <S.SpecialInterestHeaderLabel>
              Dow Jones has identified your subject as{" "}
              {!hasDirectScreeningHits &&
                "closely associated with individuals "}
              linked to the following categories of risk:
            </S.SpecialInterestHeaderLabel>
            <S.SpecialInterestCategoriesList>
              {specialInterestRiskCategories?.map(flag => (
                <li key={flag.category}>
                  <S.Category>{flag.category}</S.Category>
                  {flag.dJSpecialInterestIsLowerThreshold && (
                    <S.LowerThresholdItem>Lower Threshold</S.LowerThresholdItem>
                  )}
                </li>
              ))}
            </S.SpecialInterestCategoriesList>
            {hasAnalystNotes() && (
              <div>
                <S.Divider />
                <S.SpecialInterestCategoriesAnalystNotesHeaderLabel>
                  Analyst&apos;s note
                </S.SpecialInterestCategoriesAnalystNotesHeaderLabel>
                {dowJonesSpecialInterestHits
                  ?.filter(entry => entry?.source?.description !== null)
                  .map(entry => (
                    <S.AnalystNote key={entry.source.id}>
                      {entry.source.description}
                    </S.AnalystNote>
                  ))}
              </div>
            )}
          </div>
        </S.SpecialInterestPopoverContent>
      ) : (
        <S.MultipleSpecialInterestPopoverContent>
          {dowJonesSpecialInterestHits?.map(category => (
            <S.SIContainer>
              <S.Wrapper>
                <S.SpecialInterestCategoriesAnalystNotesHeaderLabel>
                  {category.entityName}
                </S.SpecialInterestCategoriesAnalystNotesHeaderLabel>
                <S.SpecialInterestHeaderLabel>
                  Dow Jones has identified a match your subject as{" "}
                  {!hasDirectScreeningHits &&
                    "closely associated with individuals "}
                  linked to the following categories of risk:
                </S.SpecialInterestHeaderLabel>
                <S.SpecialInterestCategoriesList>
                  {category.riskCategories.map(riskCategory => {
                    return (
                      <li key={category}>
                        <S.Category>{riskCategory.category}</S.Category>
                      </li>
                    );
                  })}
                </S.SpecialInterestCategoriesList>
                {category.source?.description && (
                  <div>
                    <S.Divider />
                    <S.SpecialInterestCategoriesAnalystNotesHeaderLabel>
                      Analyst&apos;s note
                    </S.SpecialInterestCategoriesAnalystNotesHeaderLabel>
                    <S.AnalystNote key={category.source.id}>
                      {category.source?.description}
                    </S.AnalystNote>
                  </div>
                )}
              </S.Wrapper>
            </S.SIContainer>
          ))}
        </S.MultipleSpecialInterestPopoverContent>
      )}
    </>
  );

  const calculateConfirmedScreeningCounts = data => {
    return data?.reduce((total, entity) => {
      return total + (entity?.listings?.length ?? 0) ?? 0;
    }, 0);
  };

  const calculateUnconfirmedScreeningCounts = data => {
    return (
      (data?.relativeOrCloseAssociate?.unconfirmed?.length ?? 0) +
      (data?.direct?.unconfirmed?.length ?? 0)
    );
  };

  const confirmedDirectSpecialInterests =
    dowJonesSpecialInterest?.direct?.confirmed?.length ?? 0;

  return (
    <>
      <ScreeningInfoGraphic
        hideHighlight
        customGraphic={
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.SANCTIONS}
            isRiskIdentified={
              (calculateConfirmedScreeningCounts(
                hasDirectScreeningHits
                  ? sanctions?.direct?.confirmed
                  : sanctions?.relativeOrCloseAssociate?.confirmed
              ) ?? sanctions?.confirmedCount) > // Backwards compat
              0
            }
          />
        }
        confirmedScreeningData={
          hasDirectScreeningHits
            ? sanctions?.direct?.confirmed
            : sanctions?.relativeOrCloseAssociate?.confirmed
        }
        confirmedScreeningCount={
          calculateConfirmedScreeningCounts(
            hasDirectScreeningHits
              ? sanctions?.direct?.confirmed
              : sanctions?.relativeOrCloseAssociate?.confirmed
          ) ?? sanctions?.confirmedCount // Backwards compat
        }
        unconfirmedScreeningCount={calculateUnconfirmedScreeningCounts(
          sanctions
        )}
        listType={SCREENING_LIST_TYPES.sanctions}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        CustomIcon={
          !hasDirectScreeningHits &&
          sanctions?.relativeOrCloseAssociate?.confirmed?.length > 0 &&
          S.RCASanctionIcon
        }
        customInspectorHeader={
          !hasDirectScreeningHits
            ? `${
                sanctions?.relativeOrCloseAssociate?.confirmed?.length
              } sanctioned ${
                sanctions?.relativeOrCloseAssociate?.confirmed?.length !== 1
                  ? "relatives or close associates"
                  : "relative or close associate"
              }`
            : null // Else let the screening component handle it - therefore, we return nothing
        }
        customConfirmedExplainerText={
          !hasDirectScreeningHits
            ? getConfirmedExplainerText(
                SCREENING_LIST_TYPES.sanctions,
                sanctions?.relativeOrCloseAssociate?.confirmed?.length
              )
            : null
        }
        customUnconfirmedExplainerText={getForReviewExplainerText(
          SCREENING_LIST_TYPES.sanctions,
          calculateUnconfirmedScreeningCounts(sanctions),
          reportType,
          sanctions?.direct?.confirmed?.length ||
            sanctions?.relativeOrCloseAssociate?.confirmed?.length
        )}
        listLabel={SCREENING_FILTERS.sanctions.label}
        displaySummaryView={!hasDirectScreeningHits}
      />
      <ScreeningInfoGraphic
        hideHighlight
        customGraphic={
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.WATCHLISTS}
            isRiskIdentified={
              (calculateConfirmedScreeningCounts(
                hasDirectScreeningHits
                  ? watchlists?.direct?.confirmed
                  : watchlists?.relativeOrCloseAssociate?.confirmed
              ) ?? watchlists?.confirmedCount) > 0
            }
          />
        }
        confirmedScreeningData={
          hasDirectScreeningHits
            ? watchlists?.direct?.confirmed
            : watchlists?.relativeOrCloseAssociate?.confirmed
        }
        confirmedScreeningCount={
          calculateConfirmedScreeningCounts(
            hasDirectScreeningHits
              ? watchlists?.direct?.confirmed
              : watchlists?.relativeOrCloseAssociate?.confirmed
          ) ?? watchlists?.confirmedCount
        } // Backwards compat
        unconfirmedScreeningCount={calculateUnconfirmedScreeningCounts(
          watchlists
        )}
        listType={SCREENING_LIST_TYPES.watchlists}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        CustomIcon={
          !hasDirectScreeningHits &&
          watchlists?.relativeOrCloseAssociate?.confirmed?.length > 0 &&
          S.RCAWatchlistIcon
        }
        customInspectorHeader={
          !hasDirectScreeningHits
            ? `${watchlists?.relativeOrCloseAssociate?.confirmed?.length} ${
                watchlists?.relativeOrCloseAssociate?.confirmed?.length !== 1
                  ? "relatives or close associates"
                  : "relative or close associate"
              } on watchlists`
            : null
        }
        customConfirmedExplainerText={
          !hasDirectScreeningHits
            ? getConfirmedExplainerText(
                SCREENING_LIST_TYPES.watchlists,
                watchlists?.relativeOrCloseAssociate?.confirmed?.length
              )
            : null
        }
        customUnconfirmedExplainerText={getForReviewExplainerText(
          SCREENING_LIST_TYPES.watchlists,
          calculateUnconfirmedScreeningCounts(watchlists),
          reportType,
          watchlists?.direct?.confirmed?.length ||
            watchlists?.relativeOrCloseAssociate?.confirmed?.length
        )}
        listLabel={SCREENING_FILTERS.watchLists.label}
        displaySummaryView={!hasDirectScreeningHits}
      />
      {reportType === REPORT_TYPES.person ? (
        <ScreeningInfoGraphic
          hideHighlight
          customGraphic={
            <RiskSummaryInfoGraphic
              topic={RiskSummaryTopic.PEPS}
              isRiskIdentified={
                (calculateConfirmedScreeningCounts(
                  hasDirectScreeningHits
                    ? peps?.direct?.confirmed
                    : peps?.relativeOrCloseAssociate?.confirmed
                ) ?? peps?.confirmedCount) > 0
              }
            />
          }
          confirmedScreeningData={
            hasDirectScreeningHits
              ? peps?.direct?.confirmed
              : peps?.relativeOrCloseAssociate?.confirmed
          }
          confirmedScreeningCount={
            calculateConfirmedScreeningCounts(
              hasDirectScreeningHits
                ? peps?.direct?.confirmed
                : peps?.relativeOrCloseAssociate?.confirmed
            ) ?? peps?.confirmedCount
          } // Backwards compat
          unconfirmedScreeningCount={calculateUnconfirmedScreeningCounts(peps)}
          listType={SCREENING_LIST_TYPES.peps}
          entityType={reportType}
          sectionToJumpToRef={
            reportSectionRefs &&
            reportSectionRefs[personReportSectionTitles.SCREENING]
          }
          CustomIcon={
            !hasDirectScreeningHits &&
            peps?.relativeOrCloseAssociate?.confirmed?.length > 0 &&
            S.RCAPepsIcon
          }
          customInspectorHeader={
            !hasDirectScreeningHits
              ? `${peps?.relativeOrCloseAssociate?.confirmed?.length} ${
                  peps?.relativeOrCloseAssociate?.confirmed?.length !== 1
                    ? "relatives or close associates"
                    : "relative or close associate"
                } to a PEP`
              : null
          }
          customConfirmedExplainerText={
            !hasDirectScreeningHits
              ? getConfirmedExplainerText(
                  SCREENING_LIST_TYPES.peps,
                  peps?.relativeOrCloseAssociate?.confirmed?.length
                )
              : null
          }
          customUnconfirmedExplainerText={getForReviewExplainerText(
            SCREENING_LIST_TYPES.peps,
            calculateUnconfirmedScreeningCounts(peps),
            reportType,
            peps?.direct?.confirmed?.length ||
              peps?.relativeOrCloseAssociate?.confirmed?.length
          )}
          listLabel={SCREENING_FILTERS.peps.label}
          displaySummaryView={!hasDirectScreeningHits}
        />
      ) : (
        // Organisation reports share the same State owned companies infographic for both PDX, and non PDX reports.
        // So this is passed through at the level of the OrganisationOverview component.
        CustomInfographic
      )}
      <ScreeningInfoGraphic
        hideHighlight
        customGraphic={
          <RiskSummaryInfoGraphic
            topic={RiskSummaryTopic.SPECIAL_INTEREST}
            isRiskIdentified={
              (hasDirectScreeningHits
                ? dowJonesSpecialInterest?.direct?.confirmed?.length
                : dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed
                    ?.length) > 0
            }
          />
        }
        confirmedScreeningCount={
          hasDirectScreeningHits
            ? dowJonesSpecialInterest?.direct?.confirmed?.length
            : dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed
                ?.length
        }
        unconfirmedScreeningCount={
          (dowJonesSpecialInterest?.direct?.unconfirmed?.length ?? 0) +
          (dowJonesSpecialInterest?.relativeOrCloseAssociate?.unconfirmed
            ?.length ?? 0)
        }
        listType={SCREENING_LIST_TYPES.specialInterest}
        entityType={reportType}
        sectionToJumpToRef={
          reportSectionRefs &&
          reportSectionRefs[personReportSectionTitles.SCREENING]
        }
        CustomIcon={
          !hasDirectScreeningHits &&
          dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed?.length >
            0
            ? S.RCASpecialInterestIcon
            : S.SpecialInterestIcon
        }
        customInspectorHeader={
          !hasDirectScreeningHits
            ? `${
                dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed
                  ?.length ?? 0
              } ${
                dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed
                  ?.length !== 1
                  ? "relatives or close associates"
                  : "relative or close associate"
              } of Special Interest`
            : `${confirmedDirectSpecialInterests} Special Interest ${
                confirmedDirectSpecialInterests === 1 ? "match" : "matches"
              }`
        }
        customConfirmedExplainerText={
          !hasDirectScreeningHits
            ? getConfirmedExplainerText(
                SCREENING_LIST_TYPES.specialInterest,
                dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed
                  ?.length,
                true
              )
            : getConfirmedExplainerText(
                SCREENING_LIST_TYPES.specialInterest,
                dowJonesSpecialInterest?.direct?.confirmed?.length
              )
        }
        customUnconfirmedExplainerText={getForReviewExplainerText(
          SCREENING_LIST_TYPES.specialInterest,
          (dowJonesSpecialInterest?.relativeOrCloseAssociate?.unconfirmed
            ?.length ?? 0) +
            (dowJonesSpecialInterest?.direct?.unconfirmed?.length ?? 0),
          reportType,
          dowJonesSpecialInterest?.direct?.confirmed?.length ||
            dowJonesSpecialInterest?.relativeOrCloseAssociate?.confirmed?.length
        )}
        listLabel={SCREENING_FILTERS.specialInterest.label}
        customInspectorContent={specialInterestContent}
        displayLinkToScreeningSection={false}
      />
    </>
  );
};

export default ThemedScreeningInfographics;
